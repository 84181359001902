<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Physicalresourcemonitoring" style="width:100vw">
  <div id="af7e9d5b">
    <div id="ad3ba247">
      物理资源监控
    </div>
    <el-tabs id="a7fe7db5" type="card">
      <el-tab-pane label="全局储存">
        <div id="a8690a97">
          <el-radio-group id="ada21755" v-model="rdg_ada21755">
            <el-radio-button id="a0415e3e" label="全部">
            </el-radio-button>
            <el-radio-button id="cd18323b" label="开发">
            </el-radio-button>
            <el-radio-button id="a68e4aa4" label="测试">
            </el-radio-button>
            <el-radio-button id="a08d0b85" label="生产">
            </el-radio-button>
          </el-radio-group>
          <div id="d686fd5f">
            <div id="aed1c4db">
              <div id="a07933c4">
                当前值
              </div>
              <div id="e2497bd9">
                30
              </div>
            </div>
            <div id="fe3f68e2">
              <div id="ae99cbb5">
                最大值
              </div>
              <div id="eb989db3">
                100
              </div>
            </div>
          </div>
          <img src="../assets/img-ac73a0a7.png" id="ac73a0a7"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据中心储存">
        <div id="d64501fa">
          <div id="a5af83dd">
            页面设计中～
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="数据库">
        <div id="e899c27b">
          <div id="ab4f888d">
            <div id="a5c57d1a">
              <div id="a198c970">
                库数
              </div>
              <div id="ab70a3be">
                100
              </div>
            </div>
            <div id="edcd6858">
              <div id="a4187885">
                存储
              </div>
              <div id="a65cd302">
                300
              </div>
            </div>
            <div id="ba5f0578">
              <div id="a8477378">
                每秒读
              </div>
              <div id="d725223c">
                234
              </div>
            </div>
            <div id="a0c3ed6d">
              <div id="dbdf979f">
                每秒写
              </div>
              <div id="ceb6e594">
                678
              </div>
            </div>
          </div>
          <div id="a0bf5a7e">
            库数
          </div>
          <el-select id="e35278a8" placeholder="过去七天" v-model="val_e35278a8">
            <el-option v-for="item in op_e35278a8" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-f9f42b28.png" id="f9f42b28"/>
          <div id="add92a9a">
            存储
          </div>
          <el-select id="aea19942" placeholder="过去七天" v-model="val_aea19942">
            <el-option v-for="item in op_aea19942" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a3ecb6af.png" id="a3ecb6af"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="物理机">
        <div id="ada828ed">
          <div id="a826f11c">
            <div id="a597595f">
              <div id="a800a3ed">
                CPU负载
              </div>
              <div id="af17380e">
                100
              </div>
            </div>
            <div id="d0c2048e">
              <div id="a1ae7a43">
                内存
              </div>
              <div id="c7645152">
                300G
              </div>
            </div>
            <div id="a6d8292c">
              <div id="ab931a71">
                网络流量
              </div>
              <div id="afb61c52">
                67G
              </div>
            </div>
            <div id="ac24a67b">
              <div id="a6547d4e">
                磁盘IO
              </div>
              <div id="d7ba0118">
                100
              </div>
            </div>
            <div id="c47ead50">
              <div id="f42f60a4">
                网络宽带
              </div>
              <div id="aa973639">
                678
              </div>
            </div>
            <div id="e2f6d20f">
              <div id="ae2a6eb3">
                系统错误日志
              </div>
              <div id="a844a444">
                6
              </div>
            </div>
          </div>
          <div id="a2908904">
            CPU负载
          </div>
          <el-select id="ac54c835" placeholder="过去七天" v-model="val_ac54c835">
            <el-option v-for="item in op_ac54c835" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a94acad1.png" id="a94acad1"/>
          <div id="b0fa3100">
            内存
          </div>
          <el-select id="ab354904" placeholder="过去七天" v-model="val_ab354904">
            <el-option v-for="item in op_ab354904" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-aa0625b4.png" id="aa0625b4"/>
          <div id="da53c18d">
            网络流量
          </div>
          <el-select id="d1cd11d8" placeholder="过去七天" v-model="val_d1cd11d8">
            <el-option v-for="item in op_d1cd11d8" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-ab303d94.png" id="ab303d94"/>
          <div id="aec6299a">
            磁盘IO
          </div>
          <el-select id="befc3506" placeholder="过去七天" v-model="val_befc3506">
            <el-option v-for="item in op_befc3506" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a1597862.png" id="a1597862"/>
        </div>
      </el-tab-pane>
      <el-tab-pane label="虚拟机">
        <div id="a4795033">
          <div id="a515153e">
            <div id="bcdb73f0">
              <div id="b8cdd0fc">
                CPU负载
              </div>
              <div id="aca4fbf5">
                100
              </div>
            </div>
            <div id="ab4478ac">
              <div id="a7f9cc4a">
                内存
              </div>
              <div id="ac4d1b5a">
                300G
              </div>
            </div>
            <div id="a8c5fa55">
              <div id="da8c080b">
                网络流量
              </div>
              <div id="ac89376d">
                67G
              </div>
            </div>
            <div id="ff7d0c7a">
              <div id="b39057ff">
                磁盘IO
              </div>
              <div id="d580b60f">
                100
              </div>
            </div>
            <div id="ae4e01cf">
              <div id="ab958c5e">
                网络宽带
              </div>
              <div id="ae807913">
                678
              </div>
            </div>
            <div id="abc149f7">
              <div id="fb784cd7">
                系统错误日志
              </div>
              <div id="afef3593">
                6
              </div>
            </div>
          </div>
          <div id="a57fa2e4">
            CPU负载
          </div>
          <el-select id="ee8c4a5c" placeholder="过去七天" v-model="val_ee8c4a5c">
            <el-option v-for="item in op_ee8c4a5c" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-afd96a8d.png" id="afd96a8d"/>
          <div id="d4b29a67">
            内存
          </div>
          <el-select id="a81e853d" placeholder="过去七天" v-model="val_a81e853d">
            <el-option v-for="item in op_a81e853d" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-a7960fc7.png" id="a7960fc7"/>
          <div id="c87f7df5">
            网络流量
          </div>
          <el-select id="af77f703" placeholder="过去七天" v-model="val_af77f703">
            <el-option v-for="item in op_af77f703" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-ae086ae8.png" id="ae086ae8"/>
          <div id="e8579961">
            磁盘IO
          </div>
          <el-select id="fc7896b0" placeholder="过去七天" v-model="val_fc7896b0">
            <el-option v-for="item in op_fc7896b0" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <img src="../assets/img-aec1eb85.png" id="aec1eb85"/>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    rdg_ada21755:'',
    op_e35278a8:[],
    val_e35278a8:0,
    op_aea19942:[],
    val_aea19942:0,
    op_ac54c835:[],
    val_ac54c835:0,
    op_ab354904:[],
    val_ab354904:0,
    op_d1cd11d8:[],
    val_d1cd11d8:0,
    op_befc3506:[],
    val_befc3506:0,
    op_ee8c4a5c:[],
    val_ee8c4a5c:0,
    op_a81e853d:[],
    val_a81e853d:0,
    op_af77f703:[],
    val_af77f703:0,
    op_fc7896b0:[],
    val_fc7896b0:0,
      //在此注释下方添加自定义变量
    op_ce746e61:[],
    val_ce746e61:'',
    op_aa3493fb:[],
    val_aa3493fb:'',
    op_a8284aa2:[],
    val_a8284aa2:'',
    op_ffd5fcf0:[],
    val_ffd5fcf0:'',
    op_fac1f0e6:[],
    val_fac1f0e6:'',
    op_a32ac098:[],
    val_a32ac098:'',
    op_df2638e6:[],
    val_df2638e6:'',
    op_a2ecf327:[],
    val_a2ecf327:'',
    op_afe78e45:[],
    val_afe78e45:'',
    op_a96b8a79:[],
    val_a96b8a79:''
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#af7e9d5b{
  width:1649px;
  position:relative;
  margin-top:1px;
}
#af7e9d5b{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 4px rgba(0,0,0,0.16);
}
#ad3ba247{
  padding-top:99px;
}
#ad3ba247{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a7fe7db5{
  width:1330px;
  margin-top:60px;
  padding-left:160px;
  padding-bottom:684px;
}
#a7fe7db5{
  vertical-align: top;
}
#a7fe7db5 /deep/ .el-tabs__item{
  font-size:22px;
  font-family:PingFang SC;
}
#a8690a97{
  width:1322px;
}
#ada21755{
  width:764px;
  padding-left:276px;
}
#ada21755{
  width:auto;
  display:inline-block;
  vertical-align: middle;
}
#a0415e3e{
  width:173px;
  display:inline-block;
  vertical-align: middle;
}
#a0415e3e{
  background-color:rgb(111,116,132,1);
  border-radius:6px 6px 6px 6px;
}
#a0415e3e .el-radio-button__inner{
  width:173px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#cd18323b{
  width:172px;
  margin-left:25px;
  display:inline-block;
  vertical-align: middle;
}
#cd18323b{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#cd18323b .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#a68e4aa4{
  width:172px;
  margin-left:25px;
  display:inline-block;
  vertical-align: middle;
}
#a68e4aa4{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#a68e4aa4 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#a08d0b85{
  width:172px;
  margin-left:25px;
  display:inline-block;
  vertical-align: middle;
}
#a08d0b85{
  background-color:rgb(255,255,255,1);
  border:2px solid #6f7484;
  border-radius:6px 6px 6px 6px;
}
#a08d0b85 .el-radio-button__inner{
  width:172px;
  height:43px;
  line-height:43px;
  font-size:16px;
  font-family:PingFang SC;
  padding:0px;
}
#d686fd5f{
  width:520px;
  position:relative;
  margin-top:80px;
  padding-left:398px;
}
#aed1c4db{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#aed1c4db{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a07933c4{
  padding-top:20px;
}
#a07933c4{
  text-align:center;
  color:#141620;
  font-size:16px;
  font-family:PingFang SC;
}
#e2497bd9{
  margin-top:20px;
  padding-bottom:23px;
}
#e2497bd9{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#fe3f68e2{
  width:203px;
  position:relative;
  margin-left:114px;
  display:inline-block;
  vertical-align: middle;
}
#fe3f68e2{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#ae99cbb5{
  padding-top:20px;
}
#ae99cbb5{
  text-align:center;
  color:#141620;
  font-size:16px;
  font-family:PingFang SC;
}
#eb989db3{
  margin-top:20px;
  padding-bottom:23px;
}
#eb989db3{
  text-align:center;
  color:#141620;
  font-size:27px;
  font-family:PingFang SC;
}
#ac73a0a7{
  width:1322px;
  height:518px;
  margin-top:80px;
}
#ac73a0a7{
  vertical-align:middle;
}
#d64501fa{
  width:1322px;
}
#d64501fa{
  background-color:rgb(252,252,255,1);
  border-radius:0px 0px 0px 0px;
}
#a5af83dd{
  padding-top:155px;
  padding-bottom:1043px;
}
#a5af83dd{
  text-align:center;
  color:#c9c9c9;
  font-size:40px;
  font-family:PingFang SC;
}
#e899c27b{
  width:1322px;
}
#ab4f888d{
  width:1314px;
  position:relative;
}
#a5c57d1a{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a5c57d1a{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a198c970{
  padding-top:20px;
}
#a198c970{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ab70a3be{
  margin-top:18px;
  padding-bottom:21px;
}
#ab70a3be{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#edcd6858{
  width:203px;
  position:relative;
  margin-left:168px;
  display:inline-block;
  vertical-align: middle;
}
#edcd6858{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a4187885{
  padding-top:20px;
}
#a4187885{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a65cd302{
  margin-top:18px;
  padding-bottom:21px;
}
#a65cd302{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ba5f0578{
  width:203px;
  position:relative;
  margin-left:167px;
  display:inline-block;
  vertical-align: middle;
}
#ba5f0578{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a8477378{
  padding-top:20px;
}
#a8477378{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#d725223c{
  margin-top:18px;
  padding-bottom:21px;
}
#d725223c{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a0c3ed6d{
  width:203px;
  position:relative;
  margin-left:167px;
  display:inline-block;
  vertical-align: middle;
}
#a0c3ed6d{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#dbdf979f{
  padding-top:20px;
}
#dbdf979f{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ceb6e594{
  margin-top:18px;
  padding-bottom:21px;
}
#ceb6e594{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a0bf5a7e{
  margin-top:60px;
}
#a0bf5a7e{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#e899c27b .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#f9f42b28{
  width:1322px;
  height:518px;
  margin-top:30px;
  padding-left:0px;
}
#f9f42b28{
  vertical-align:middle;
}
#add92a9a{
  margin-top:60px;
}
#add92a9a{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#e899c27b .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a3ecb6af{
  width:1322px;
  height:518px;
  margin-top:30px;
  padding-left:0px;
}
#a3ecb6af{
  vertical-align:middle;
}
#ada828ed{
  width:1322px;
}
#a826f11c{
  width:1314px;
  position:relative;
  padding-left:1px;
}
#a597595f{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a597595f{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a800a3ed{
  padding-top:20px;
}
#a800a3ed{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#af17380e{
  margin-top:18px;
  padding-bottom:21px;
}
#af17380e{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#d0c2048e{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#d0c2048e{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a1ae7a43{
  padding-top:20px;
}
#a1ae7a43{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#c7645152{
  margin-top:18px;
  padding-bottom:21px;
}
#c7645152{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a6d8292c{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#a6d8292c{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#ab931a71{
  padding-top:20px;
}
#ab931a71{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#afb61c52{
  margin-top:18px;
  padding-bottom:21px;
}
#afb61c52{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ac24a67b{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#ac24a67b{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a6547d4e{
  padding-top:20px;
}
#a6547d4e{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#d7ba0118{
  margin-top:18px;
  padding-bottom:21px;
}
#d7ba0118{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#c47ead50{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#c47ead50{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#f42f60a4{
  padding-top:20px;
}
#f42f60a4{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#aa973639{
  margin-top:18px;
  padding-bottom:21px;
}
#aa973639{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#e2f6d20f{
  width:203px;
  position:relative;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#e2f6d20f{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#ae2a6eb3{
  padding-top:20px;
}
#ae2a6eb3{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#a844a444{
  margin-top:18px;
  padding-bottom:21px;
}
#a844a444{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a2908904{
  margin-top:60px;
}
#a2908904{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ada828ed .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a94acad1{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a94acad1{
  vertical-align:middle;
}
#b0fa3100{
  margin-top:60px;
}
#b0fa3100{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ada828ed .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#aa0625b4{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#aa0625b4{
  vertical-align:middle;
}
#da53c18d{
  margin-top:60px;
}
#da53c18d{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ada828ed .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#ab303d94{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#ab303d94{
  vertical-align:middle;
}
#aec6299a{
  margin-top:60px;
}
#aec6299a{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#ada828ed .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a1597862{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a1597862{
  vertical-align:middle;
}
#a4795033{
  width:1322px;
}
#a515153e{
  width:1314px;
  position:relative;
  padding-left:1px;
}
#bcdb73f0{
  width:203px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#bcdb73f0{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#b8cdd0fc{
  padding-top:20px;
}
#b8cdd0fc{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#aca4fbf5{
  margin-top:18px;
  padding-bottom:21px;
}
#aca4fbf5{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ab4478ac{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#ab4478ac{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#a7f9cc4a{
  padding-top:20px;
}
#a7f9cc4a{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ac4d1b5a{
  margin-top:18px;
  padding-bottom:21px;
}
#ac4d1b5a{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a8c5fa55{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#a8c5fa55{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#da8c080b{
  padding-top:20px;
}
#da8c080b{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ac89376d{
  margin-top:18px;
  padding-bottom:21px;
}
#ac89376d{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ff7d0c7a{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#ff7d0c7a{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#b39057ff{
  padding-top:20px;
}
#b39057ff{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#d580b60f{
  margin-top:18px;
  padding-bottom:21px;
}
#d580b60f{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#ae4e01cf{
  width:203px;
  position:relative;
  margin-left:19px;
  display:inline-block;
  vertical-align: middle;
}
#ae4e01cf{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#ab958c5e{
  padding-top:20px;
}
#ab958c5e{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#ae807913{
  margin-top:18px;
  padding-bottom:21px;
}
#ae807913{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#abc149f7{
  width:203px;
  position:relative;
  margin-left:20px;
  display:inline-block;
  vertical-align: middle;
}
#abc149f7{
  background-color:rgb(255,255,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:0px 0px 12px rgba(0,0,0,0.17);
}
#fb784cd7{
  padding-top:20px;
}
#fb784cd7{
  text-align:center;
  color:#141620;
  font-size:17px;
  font-family:PingFang SC;
}
#afef3593{
  margin-top:18px;
  padding-bottom:21px;
}
#afef3593{
  text-align:center;
  color:#141620;
  font-size:28px;
  font-family:PingFang SC;
}
#a57fa2e4{
  margin-top:60px;
}
#a57fa2e4{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a4795033 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#afd96a8d{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#afd96a8d{
  vertical-align:middle;
}
#d4b29a67{
  margin-top:60px;
}
#d4b29a67{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a4795033 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#a7960fc7{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#a7960fc7{
  vertical-align:middle;
}
#c87f7df5{
  margin-top:60px;
}
#c87f7df5{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a4795033 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#ae086ae8{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#ae086ae8{
  vertical-align:middle;
}
#e8579961{
  margin-top:60px;
}
#e8579961{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a4795033 .el-select{
  width:158px;
  margin-top:30px;
  margin-left:1164px;
}
#aec1eb85{
  width:1322px;
  height:518px;
  margin-top:30px;
}
#aec1eb85{
  vertical-align:middle;
}
/*在此注释下方添加自定义样式*/
</style>
